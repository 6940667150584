<template>
  <div>
    <b-form-group
      label="Nombre:"
      label-for="input-name"
      label-cols="0"
      label-cols-sm="4"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-name"
        name="input-name"
        v-model="$v.commune.name.$model"
        :state="validateState('name')"
        aria-describedby="input-name-live-feedback"
        size="sm"
        class="input-name"
      ></b-form-input>
      <b-form-invalid-feedback id="input-name-live-feedback"
        >Este campo debe tener al menos 3 caracteres.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Orden:"
      label-for="input-order"
      label-cols="0"
      label-cols-sm="4"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-order"
        name="input-order"
        type="number"
        v-model="$v.commune.order.$model"
        :state="validateState('order')"
        aria-describedby="input-order-live-feedback"
        size="sm"
        class="input-order"
      ></b-form-input>
      <b-form-invalid-feedback id="input-order-live-feedback"
        >Este campo debe ser numérico y mayor a 0</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      v-if="countries.length > 1"
      :label="`${$getVisibleNames('common.country', false, 'País')}:`"
      label-for="select-country"
      label-cols="0"
      label-cols-sm="4"
      class="m-0 p-0"
    >
      <v-select
        id="select-country"
        :options="countries"
        v-model="$v.selected_country.$model"
        @input="(value) => (commune.region = null)"
        :reduce="(countries) => countries.id"
        :state="validateCountry()"
        :placeholder="`${
          countries.length > 0
            ? String(
                'Seleccione una ' +
                  $getVisibleNames(
                    'common.country',
                    false,
                    'País'
                  ).toLowerCase()
              )
            : String(
                'No cuenta con ' +
                  $getVisibleNames(
                    'common.country',
                    true,
                    'Países'
                  ).toLowerCase() +
                  ' creados'
              )
        }`"
        label="name"
        track-by="id"
        size="sm"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontró resultados para <em>{{ search }}</em
            >.
          </template>
        </template>
      </v-select>
      <div
        class="div-invalid-feedback"
        v-if="$v.selected_country.$anyError == true"
      >
        Debe seleccionar
        {{ $getVisibleNames("common.country", false, "País") }}.
      </div>
    </b-form-group>
    <b-form-group
      v-if="selected_country || countries.length == 1"
      :label="`${$getVisibleNames('common.region', false, 'Región')}:`"
      label-for="select-region"
      label-cols="0"
      label-cols-sm="4"
      class="m-0 p-0"
    >
      <v-select
        id="select-region"
        :options="filterRegions"
        v-model="$v.commune.region.$model"
        @input="
          (value) =>
            (commune.order =
              1 + communes.filter((x) => x.region == value).length)
        "
        :reduce="(filterRegions) => filterRegions.id"
        :state="validateState('region')"
        :placeholder="`${
          filterRegions.length > 0
            ? String(
                'Seleccione una ' +
                  $getVisibleNames(
                    'common.region',
                    false,
                    'Región'
                  ).toLowerCase()
              )
            : String(
                'No cuenta con ' +
                  $getVisibleNames(
                    'common.region',
                    true,
                    'Regiones'
                  ).toLowerCase() +
                  ' creadas'
              )
        }`"
        label="name"
        track-by="id"
        size="sm"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontró resultados para <em>{{ search }}</em
            >.
          </template>
        </template>
      </v-select>
      <div
        class="div-invalid-feedback"
        v-if="$v.commune.region.$anyError == true && commune.region == null"
      >
        Debe seleccionar
        {{ $getVisibleNames("common.region", false, "Región") }}.
      </div>
    </b-form-group>
    <div class="row mt-3">
      <div
        v-if="show_delete_button && !isNaN(this.commune.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteCommune"
          >Eliminar</b-button
        >
      </div>
      <div v-if="show_save_button" class="col" style="text-align: right">
        <b-button class="m-0 mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  numeric,
  minValue,
} from "vuelidate/lib/validators";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "CommuneForm",
  mixins: [validationMixin],
  components: {
    vSelect,
  },
  props: {
    Commune: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          order: 1,
          region: null,
        };
      },
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    show_title: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      commune: { ...this.Commune },
      selected_country: null,
    };
  },
  validations: {
    commune: {
      name: {
        required,
        minLength: minLength(3),
      },
      order: {
        required,
        numeric,
        minValue: minValue(1),
      },
      region: {
        required,
      },
    },
    selected_country: { required },
    validationGroup: ["commune", "selected_country"],
  },
  computed: {
    ...mapGetters({
      communes: names.COMMUNES,
      regions: names.REGIONS,
      countries: names.COUNTRIES,
    }),
    filterRegions() {
      if (this.countries.length > 1) {
        if (this.selected_country) {
          return this.regions.filter((x) => x.country == this.selected_country);
        } else return [];
      } else {
        return this.regions;
      }
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.commune[name];
      return $dirty ? !$error : null;
    },
    validateCountry() {
      const { $dirty, $error } = this.$v.selected_country;
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      if (isNaN(this.commune.id)) {
        this.createCommune();
      } else this.updateCommune();
    },
    createCommune() {
      this.$store
        .dispatch(names.POST_COMMUNE, this.commune)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("common.commune", false, "Comuna") +
                " creada."
            )
          );
          this.$emit("created", response);
        });
    },
    updateCommune() {
      this.$store
        .dispatch(names.UPDATE_COMMUNE, this.commune)
        .then((response) => {
          toast(
            String(
              this.$getVisibleNames("common.commune", false, "Comuna") +
                " actualizada."
            )
          );
          this.$emit("updated", response);
        });
    },
    deleteCommune() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "common.commune",
          false,
          "Comuna"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_COMMUNE, this.commune.id);
          this.$emit("deleted", this.commune);
        }
      });
    },
  },
  mounted() {
    if (this.countries.length == 1)
      this.selected_country = this.countries[0].id;
    else if (!isNaN(this.commune.id)) {
      const region = this.regions.find((x) => x.id == this.commune.region);
      if (region) this.selected_country = region.country;
    }
  },
  created() {},
};
</script>

<style scoped>
.label {
  margin-top: 1%;
}
.input-name {
  margin-top: -1px;
}
#input-color {
  cursor: pointer;
}
.div-invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>